const menuList = [
  {
    id: 1,
    label: 'Home',
    href: '/#home',
  },
  {
    id: 2,
    label: 'Services',
    href: '/#services',
  },
  {
    id: 3,
    label: 'Reviews',
    href: '/#reviews',
  },
  {
    id: 4,
    label: 'Gallery',
    href: '/#gallery',
  },
  {
    id: 5,
    label: 'Contact Us',
    href: '/#info',
  },
];

export default menuList;
