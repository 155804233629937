const palette = {
  primary: '#fefefe',
  secondary: '#252525',
  box: '#f7f7f7',
  text: '#373a36',
  boxShadow: 'hsla(0 0% 0% / 0.1) 0 0.5rem 1rem',
  line: 'rgba(0,0,0,.1)',
};

export default palette;
